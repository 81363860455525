import React, { Suspense, createContext } from "react";
import './App.css';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import routes from './config/routes'
import axios from 'axios'

// Common
import Layout from "./includes/Layout";
export const ApiContext = createContext();
const Login = React.lazy(() => import('./pages/Login'));

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // API
            host: '/api',
            auth: '/admin/auth',
            announcement_list_api: '/announcements',
            announcement_regist_api: '/announcements',
            announcement_edit_api: '/announcements/',
            announcement_detail_api: '/announcements/',
            administrator_list_api: '/administrators',
            administrator_regist_api: '/administrators',
            administrator_edit_api: '/administrators/',
            administrator_detail_api: '/administrators/',
            user_list_api: '/users',
            user_regist_api: '/users',
            user_edit_api: '/users/',
            user_detail_api: '/users/',
            questionnaire_list_api: '/questionnaires',
            questionnaire_detail_api: '/questionnaires/',
            labeling_draft_list_api: '/labelings',
            administrators_list_api: '/administrators',
            axiosStatusCode: 200,
        };
    }

    componentDidMount() {
        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (error.response.status == 401) {
                    console.log(error.response.status)
                    this.setState({
                        axiosStatusCode : error.response.status
                    })
                }
                //return error promise for handling error in components
                return Promise.reject(error);
            }
        )

        // console.log('routes: ', routes);
    }

    render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <ApiContext.Provider value={{...this.state}}>
                    <BrowserRouter>
                        {this.state.axiosStatusCode == 401 ?
                            <>
                                <Route exact path={'/login'} component={Login} />
                                <Redirect from="/" to='/login'/>
                            </>
                            :
                            <Layout>
                                <Switch>
                                    {routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={props => (<route.component {...props} />)}
                                        />
                                    ))}
                                    <Redirect from="/login" to='/' />
                                    <Redirect from="" to='/' />
                                </Switch>
                            </Layout>
                        }
                    </BrowserRouter>
                </ApiContext.Provider>
            </Suspense>
        );
    }
}

export default App;
