import React, { Component } from 'react'

// Layouts
import Side from './Side';
import Header from './Header';

const Layout = (props) => (
    <div id="Site">
        <Header />
        <div className="l-contents">
            <Side />
            {props.children}
        </div>
    </div>
)
export default Layout;
