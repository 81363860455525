import React, { useEffect } from 'react';
import {
    Link,
} from 'react-router-dom';

const Side = () => {
    useEffect(() => {
        // Sidebar toggle
        const openCls = 'is-open';
        const openObjArr = [
            {
                btn:'.js-sidebarOpen',
                tergetWrap:'.l-sidebar'
            },
        ];

        function toggleOpenClass(target){

            if(target.classList.contains(openCls)){
                target.classList.remove(openCls);
            } else{
                target.classList.add(openCls);
            }

        }

        openObjArr.forEach(function (openObj) {

            const targets = document.querySelectorAll(openObj.tergetWrap);

            targets.forEach(function (target) {

                const btn = target?target.querySelectorAll(openObj.btn)[0]:null;
                const closeBtn = target?target.querySelectorAll(openObj.closeBtn)[0]:null;
                const parentTarget = openObj.parent?target.closest(openObj.parent):null;


                if(btn){
                    btn.addEventListener('click',function(e){
                        e.preventDefault();
                        toggleOpenClass(target);
                        if(parentTarget){
                            toggleOpenClass(parentTarget);
                        }
                    });
                }

                if(closeBtn){
                    closeBtn.addEventListener('click',function(e){
                        e.preventDefault();
                        target.classList.remove(openCls);
                    });
                }

            });

        });

    }, []);

    return (
        <>
            <div className="l-contents__side">
                <aside className="l-sidebar l-sidebar--bg-gray">
                    <div className="l-sidebar__inner">

                        <ul className="l-sidebar__menu">
                            <li className="c-menuBtn">
                                <Link to="/" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_home_black01.svg" alt="" /><img
                                        src="/assets/images/icon_home_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">ホーム</div>
                                </Link>
                            </li>
                            <li className="c-menuBtn">
                                <a href="/news" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_bell_black01.svg" alt="" /><img
                                        src="/assets/images/icon_bell_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">お知らせ</div>
                                </a>
                            </li>
                            <li className="c-menuBtn">
                                <Link to="/titleSetting" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_tag_black01.svg" alt="" /><img
                                        src="/assets/images/icon_tag_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">タイトル設定</div>
                                </Link>
                            </li>
                            <li className="c-menuBtn">
                                <Link to="/questionnaire" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_checklist_black01.svg" alt="" /><img
                                        src="/assets/images/icon_checklist_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">アンケート</div>
                                </Link>
                            </li>
                            <li className="c-menuBtn">
                                <a href="/administrators" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_admin_black01.svg" alt="" /><img
                                        src="/assets/images/icon_admin_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">管理者設定</div>
                                </a>
                            </li>
                            <li className="c-menuBtn">
                                <a href="/users" className="c-menuBtn__inner">
                                    <span
                                        className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon"><img
                                        src="/assets/images/icon_user_black01.svg" alt="" /><img
                                        src="/assets/images/icon_user_blue01.svg" alt="" /></span>
                                    <div className="c-menuBtn__title side__menuTitle">利用者設定</div>
                                </a>
                            </li>
                        </ul>


                        <div className="l-sidebar__open">
                            <a href="#" className="js-sidebarOpen">
                                <span className="c-roundIcon c-roundIcon--hov-bgWhite">
                                    <span className="c-roundIcon__arrow c-roundIcon__arrow--right"></span>
                                </span>
                            </a>
                        </div>


                    </div>
                </aside>
            </div>
        </>
    )
};

export default Side;
