import React, { useEffect, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

import { ApiContext } from '../App'

const Header = () => {
    const api = useContext(ApiContext);
    const auth = api.auth

    const [me, setMe] = useState({});

    useEffect((() => {
        axios.get(api.host + "/me")
        .then(result => {
            setMe(result.data.data);
            console.log('resultA: ', result.data.data);
        })
        .catch(error => {
            console.log('error: ', error);
        });
    }), []);

    // Header account toggle
    useEffect(() => {
        const openCls = 'is-open';
        const openObjArr = [
            {
                btn:'.js-accountMenuBtnOpen',
                tergetWrap:'.c-accountMenuBtn'
            },
        ];

        function toggleOpenClass(target){

            if(target.classList.contains(openCls)){
                target.classList.remove(openCls);
            } else{
                target.classList.add(openCls);
            }

        }

        openObjArr.forEach(function (openObj) {

            const targets = document.querySelectorAll(openObj.tergetWrap);

            targets.forEach(function (target) {

                const btn = target?target.querySelectorAll(openObj.btn)[0]:null;
                const closeBtn = target?target.querySelectorAll(openObj.closeBtn)[0]:null;
                const parentTarget = openObj.parent?target.closest(openObj.parent):null;


                if(btn){
                    btn.addEventListener('click',function(e){
                        e.preventDefault();
                        toggleOpenClass(target);
                        if(parentTarget){
                            toggleOpenClass(parentTarget);
                        }
                    });
                }

                if(closeBtn){
                    closeBtn.addEventListener('click',function(e){
                        e.preventDefault();
                        target.classList.remove(openCls);
                    });
                }

            });

        });
    }, []);

    const logout = (e) => {
        e.preventDefault()
        window.location.href = '/admin/auth/logout';
    }

    return (
        <>
            <header className="l-header l-header--bg-gray">

                <Link to="/" className="l-header__link">
                    <h1 className="l-header__logo"><img src="/assets/images/viewpoint_logo.svg" alt="ViewPoint" /><span
                        className="l-header__title">管理画面</span></h1>
                </Link>

                <div className="l-header__account c-accountMenuBtn" style={{display: "flex"}}>
                    <img height="30" width="150" src="/assets/images/logo_sample01.svg" alt="CROSS DISCOVER" style={{display: "block"}} />
                    <div className="c-accountMenuBtn__btn">
                        <div className="c-accountMenuBtn__btnInner js-accountMenuBtnOpen">
                            <span className="c-accountMenuBtn__icon c-roundIcon c-roundIcon--hov-bgWhite c-roundIcon--hov-icon">
                                <img src="/assets/images/icon_person_black01.svg" alt="" />
                                <img src="/assets/images/icon_person_blue01.svg" alt="" />
                            </span>
                            <div className="c-accountMenuBtn__name">{me.email && me.email.substr(0, me.email.indexOf('@') + 1) + '...'}</div>
                        </div>
                    </div>

                    <ul className="c-accountMenuBtn__list c-accountMenuBtn__list--bg-gray">
                        <li><Link to="#" onClick={(e) => logout(e)}>ログアウト</Link></li>
                    </ul>

                </div>
            </header>
        </>
    )
};

export default Header;
