import React from 'react';

const Main = React.lazy(() => import('../pages/Main'));
const TitleSettingList = React.lazy(() => import('../pages/title/TitleSettingList'));
const TitleSettingDetail = React.lazy(() => import('../pages/title/TitleSettingDetail'));
const TitleSettingDetailParts = React.lazy(() => import('../pages/title/TitleSettingDetailParts'));
const QuestionnaireList = React.lazy(() => import('../pages/questionnaire/QuestionnaireList'));
const QuestionnaireDetail = React.lazy(() => import('../pages/questionnaire/QuestionnaireDetail'));
const QuestionnairePreview = React.lazy(() => import('../pages/questionnaire/QuestionnairePreview'));

const AdministratorList = React.lazy(() => import('../pages/administrator/AdministratorList'));
const AdministratorRegist = React.lazy(() => import('../pages/administrator/AdministratorRegist'));
const AdministratorDetail = React.lazy(() => import('../pages/administrator/AdministratorDetail'));
const AdministratorEdit = React.lazy(() => import('../pages/administrator/AdministratorEdit'));

const UserList = React.lazy(() => import('../pages/user/UserList'));
const UserRegist = React.lazy(() => import('../pages/user/UserRegist'));
const UserDetail = React.lazy(() => import('../pages/user/UserDetail'));
const UserEdit = React.lazy(() => import('../pages/user/UserEdit'));

const NewsList = React.lazy(() => import('../pages/news/NewsList'));
const NewsRegist = React.lazy(() => import('../pages/news/NewsRegist'));
const NewsDetail = React.lazy(() => import('../pages/news/NewsDetail'));
const NewsEdit = React.lazy(() => import('../pages/news/NewsEdit'));

// const NotFound = React.lazy(() => import('../pages/404'));

const routes = [
    { path: '/', exact: true, name: 'Main', component: Main },
    { path: '/titleSetting', exact: true, name: 'TitleSettingList', component: TitleSettingList },
    { path: '/titleSetting/detail/:id', exact: true, name: 'TitleSettingDetail', component: TitleSettingDetail },
    { path: '/titleSetting/detail/parts/:id', exact: true, name: 'TitleSettingDetailParts', component: TitleSettingDetailParts },
    { path: '/questionnaire', exact: true, name: 'QuestionnaireList', component: QuestionnaireList },
    { path: '/questionnaire/detail/:id', exact: true, name: 'QuestionnaireDetail', component: QuestionnaireDetail },
    { path: '/questionnaire/detail/preview/:id', exact: true, name: 'QuestionnairePreview', component: QuestionnairePreview },
    { path: '/administrators', exact: true, name: 'AdministratorList', component: AdministratorList },
    { path: '/administrators/regist', exact: true, name: 'AdministratorRegist', component: AdministratorRegist },
    { path: '/administrators/detail/:id', exact: true, name: 'AdministratorDetail', component: AdministratorDetail },
    { path: '/administrators/edit/:id', exact: true, name: 'AdministratorEdit', component: AdministratorEdit },
    { path: '/users', exact: true, name: 'UserList', component: UserList },
    { path: '/users/regist', exact: true, name: 'UserRegist', component: UserRegist },
    { path: '/users/detail/:id', exact: true, name: 'UserDetail', component: UserDetail },
    { path: '/users/edit/:id', exact: true, name: 'UserEdit', component: UserEdit },
    { path: '/news', exact: true, name: 'NewsList', component: NewsList },
    { path: '/news/regist', exact: true, name: 'NewsRegist', component: NewsRegist },
    { path: '/news/detail/:id', exact: true, name: 'NewsDetail', component: NewsDetail },
    { path: '/news/edit/:id', exact: true, name: 'NewsEdit', component: NewsEdit },
    // { path: '', exact: false, name: 'NotFound', component: NotFound },
]

export default routes;
